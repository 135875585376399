/*
 * @Author: 张博洋
 * @Date: 2021-08-13 16:15:33
 * @LastEditTime: 2021-08-13 16:15:47
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/mixin/DropdownMenu.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import {
  DropdownMenu,
  DropdownItem
} from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[DropdownMenu.name] = DropdownMenu;
    obj[DropdownItem.name] = DropdownItem;
    return obj;
  })(),
}
