import {
    //tab
    Tab,
    Tabs
} from 'vant';

export default {
    components: (() => {
        let obj = {};
        obj[Tab.name] = Tab;
        obj[Tabs.name] = Tabs;
        return obj;
    })(),
}
